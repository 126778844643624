<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="labels"
    style="height: 500px;"
  />
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { CChartLine } from '@coreui/vue-chartjs';

export default {
  name: 'CChartLineExample',
  components: { CChartLine },
  data() {
    return {
      labels: [],
      recordCounts: [],
      totalNilaiKontrak: []
    };
  },
  computed: {
    defaultDatasets() {
      return [
        {
          label: 'Jumlah Prasarana',
          borderColor: 'rgb(76, 175, 80)',
          backgroundColor: 'rgba(76, 175, 80, 0)',
          data: this.recordCounts, // Data for 'Jumlah Prasarana'
          type: 'line',
        },
        {
          label: 'Nilai Kontrak',
          borderColor: 'rgb(0, 0, 0)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          data: this.totalNilaiKontrak, // Now using recordCounts
          type: 'line',
        }
      ];
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const label = this.labels[tooltipItem.dataIndex];
                const value = tooltipItem.raw;
                return `${tooltipItem.dataset.label}: ${value}, TAHUN: ${label}`;
              }
            }
          }
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Tahun Anggaran'
            },
            ticks: {
              major: {
                font: {
                  style: 'bold',
                  color: '#FF0000'
                }
              }
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Value'
            }
          }
        }
      };
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      console.log('Component is mounting...');
      try {
        console.log('About to fetch data...');
        const response = await this.$store.dispatch('paket_pekerjaan/persentaseTahunan');
        console.log('Data fetched:', response);
        const data = response.data; // Assuming response.data contains the data
        if (Array.isArray(data)) {
          const labelsArray = data.map(item => item.tahun_anggaran);
          const recordCountsArray = data.map(item => item.record_count);
          const totalNilaiKontrakArray = data.map(item => item.total_nilai_kontrak);

          this.labels = labelsArray;
          this.recordCounts = recordCountsArray;
          this.totalNilaiKontrak = totalNilaiKontrakArray;
        } else {
          console.error('Unexpected data format:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }
};
</script>

<style scoped>
/* Add any specific styles for the chart if needed */
</style>
